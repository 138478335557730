body {
  margin: 0;
  font-family:
    /* "JalnanGothic", */
    "Pretendard",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "JalnanGothic";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 300;
  src: url("/public/assets/font/Pretendard/Pretendard-Light.otf")
    format("opentype");
  font-display: block;
}

@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  src: url("/public/assets/font/Pretendard/Pretendard-Regular.otf")
    format("opentype");
  font-display: block;
}

@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  src: url("/public/assets/font/Pretendard/Pretendard-Bold.otf")
    format("opentype");
  font-display: block;
}

code {
  font-family: "Pretendard", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
